import React from "react"
import RacepageTdF from "../components/racepageOneDay"

const BeneluxTour2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Benelux Tour",
      edition: 2021,
      what: "Race data",
      updated: "2021-09-01 15:59:09",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21798: {
        teamId: "21798",
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00ebn Team",
        teamNationCode: "FRA",
      },
      21799: {
        teamId: "21799",
        teamUciCode: "APT",
        teamName: "Astana-Premier Tech",
        teamNationCode: "KAZ",
      },
      21800: {
        teamId: "21800",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21805: {
        teamId: "21805",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      21807: {
        teamId: "21807",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      21808: {
        teamId: "21808",
        teamUciCode: "DQT",
        teamName: "Deceuninck-Quick Step",
        teamNationCode: "BEL",
      },
      21811: {
        teamId: "21811",
        teamUciCode: "EFN",
        teamName: "EF Education-NIPPO",
        teamNationCode: "USA",
      },
      21813: {
        teamId: "21813",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21817: {
        teamId: "21817",
        teamUciCode: "ISN",
        teamName: "Israel Start-Up Nation",
        teamNationCode: "ISR",
      },
      21819: {
        teamId: "21819",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21821: {
        teamId: "21821",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange",
        teamNationCode: "AUS",
      },
      21822: {
        teamId: "21822",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21824: {
        teamId: "21824",
        teamUciCode: "TQA",
        teamName: "Team Qhubeka NextHash",
        teamNationCode: "RSA",
      },
      21826: {
        teamId: "21826",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21829: {
        teamId: "21829",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21832: {
        teamId: "21832",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "GER",
      },
      21836: {
        teamId: "21836",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21838: {
        teamId: "21838",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21844: {
        teamId: "21844",
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
      },
      21852: {
        teamId: "21852",
        teamUciCode: "BWB",
        teamName: "Bingoal Pauwels Sauces WB",
        teamNationCode: "BEL",
      },
      21855: {
        teamId: "21855",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        teamNationCode: "BEL",
      },
      21863: {
        teamId: "21863",
        teamUciCode: "SVB",
        teamName: "Sport Vlaanderen-Baloise",
        teamNationCode: "BEL",
      },
    },
    riders: {
      7720: {
        id: 7720,
        startno: 132,
        firstName: "Dylan",
        lastName: "Groenewegen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-06-21",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 98,
          overallPosition: 98,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 107,
          overallPosition: 81,
          stageTime: "00:01:31",
          overallTime: "00:02:28",
        },
        "stage-3": {
          stagePosition: 34,
          overallPosition: 65,
          stageTime: "00:00:04",
          overallTime: "00:02:28",
        },
      },
      43007: {
        id: 43007,
        startno: 97,
        firstName: "Benjamin",
        lastName: "Thomas",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-09-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 149,
          overallPosition: 149,
          stageTime: "00:13:50",
          overallTime: "00:14:00",
        },
        "stage-2": {
          stagePosition: 13,
          overallPosition: 143,
          stageTime: "00:00:36",
          overallTime: "00:14:26",
        },
        "stage-3": {
          stagePosition: 104,
          overallPosition: 142,
          stageTime: "00:01:06",
          overallTime: "00:15:28",
        },
      },
      784: {
        id: 784,
        startno: 61,
        firstName: "Peter",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1990-01-26",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 109,
          overallPosition: 109,
          stageTime: "00:02:26",
          overallTime: "00:02:36",
        },
        "stage-2": {
          stagePosition: 63,
          overallPosition: 105,
          stageTime: "00:01:08",
          overallTime: "00:03:34",
        },
        "stage-3": {
          stagePosition: 6,
          overallPosition: 86,
          stageTime: "00:00:04",
          overallTime: "00:03:34",
        },
      },
      729: {
        id: 729,
        startno: 137,
        firstName: "Jos",
        lastName: "Van Emden",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-02-18",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 85,
          overallPosition: 85,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 11,
          overallPosition: 33,
          stageTime: "00:00:32",
          overallTime: "00:01:29",
        },
        "stage-3": {
          stagePosition: 133,
          overallPosition: 95,
          stageTime: "00:02:52",
          overallTime: "00:04:17",
        },
      },
      14780: {
        id: 14780,
        startno: 214,
        firstName: "Kenneth",
        lastName: "Van Rooy",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-10-08",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 38,
          overallPosition: 38,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 84,
          overallPosition: 66,
          stageTime: "00:01:17",
          overallTime: "00:02:14",
        },
        "stage-3": {
          stagePosition: 45,
          overallPosition: 51,
          stageTime: "00:00:04",
          overallTime: "00:02:14",
        },
      },
      6414: {
        id: 6414,
        startno: 167,
        firstName: "Jasha",
        lastName: "S\u00fctterlin",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-04",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 147,
          overallPosition: 147,
          stageTime: "00:13:50",
          overallTime: "00:14:00",
        },
        "stage-2": {
          stagePosition: 38,
          overallPosition: 144,
          stageTime: "00:00:52",
          overallTime: "00:14:42",
        },
        "stage-3": {
          stagePosition: 96,
          overallPosition: 143,
          stageTime: "00:01:06",
          overallTime: "00:15:44",
        },
      },
      84019: {
        id: 84019,
        startno: 11,
        firstName: "Remco",
        lastName: "Evenepoel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-01-25",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 67,
          overallPosition: 67,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 18,
          overallPosition: 37,
          stageTime: "00:00:39",
          overallTime: "00:01:36",
        },
        "stage-3": {
          stagePosition: 115,
          overallPosition: 85,
          stageTime: "00:01:59",
          overallTime: "00:03:31",
        },
      },
      108732: {
        id: 108732,
        startno: 81,
        firstName: "Daniel",
        lastName: "Arroyave",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "2000-06-19",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 108,
          overallPosition: 108,
          stageTime: "00:02:26",
          overallTime: "00:02:36",
        },
        "stage-2": {
          stagePosition: 77,
          overallPosition: 106,
          stageTime: "00:01:14",
          overallTime: "00:03:40",
        },
        "stage-3": {
          stagePosition: 107,
          overallPosition: 102,
          stageTime: "00:01:24",
          overallTime: "00:05:00",
        },
      },
      19274: {
        id: 19274,
        startno: 171,
        firstName: "Victor",
        lastName: "Campenaerts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-28",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 17,
          overallPosition: 21,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 8,
          overallPosition: 5,
          stageTime: "00:00:26",
          overallTime: "00:00:26",
        },
        "stage-3": {
          stagePosition: 46,
          overallPosition: 6,
          stageTime: "00:00:04",
          overallTime: "00:00:26",
        },
      },
      9114: {
        id: 9114,
        startno: 102,
        firstName: "Sebastian",
        lastName: "Henao",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1993-08-05",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 146,
          overallPosition: 146,
          stageTime: "00:13:50",
          overallTime: "00:14:00",
        },
        "stage-2": {
          stagePosition: 120,
          overallPosition: 147,
          stageTime: "00:01:42",
          overallTime: "00:15:32",
        },
        "stage-3": {
          stagePosition: 98,
          overallPosition: 145,
          stageTime: "00:01:06",
          overallTime: "00:16:34",
        },
      },
      16690: {
        id: 16690,
        startno: 72,
        firstName: "Tom",
        lastName: "Bohli",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1994-01-17",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 106,
          overallPosition: 106,
          stageTime: "00:02:19",
          overallTime: "00:02:29",
        },
        "stage-2": {
          stagePosition: 60,
          overallPosition: 103,
          stageTime: "00:01:07",
          overallTime: "00:03:26",
        },
        "stage-3": {
          stagePosition: 85,
          overallPosition: 92,
          stageTime: "00:00:42",
          overallTime: "00:04:04",
        },
      },
      6228: {
        id: 6228,
        startno: 21,
        firstName: "Tim",
        lastName: "Wellens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-05-10",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 22,
          overallPosition: 24,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 23,
          overallPosition: 10,
          stageTime: "00:00:46",
          overallTime: "00:00:46",
        },
        "stage-3": {
          stagePosition: 26,
          overallPosition: 10,
          stageTime: "00:00:04",
          overallTime: "00:00:46",
        },
      },
      28781: {
        id: 28781,
        startno: 106,
        firstName: "Gianni",
        lastName: "Moscon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-04-20",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 56,
          overallPosition: 56,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 48,
          overallPosition: 53,
          stageTime: "00:00:59",
          overallTime: "00:01:56",
        },
        "stage-3": {
          stagePosition: 27,
          overallPosition: 43,
          stageTime: "00:00:04",
          overallTime: "00:01:56",
        },
      },
      5059: {
        id: 5059,
        startno: 182,
        firstName: "Jasper",
        lastName: "Stuyven",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-04-17",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 31,
          overallPosition: 31,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 39,
          overallPosition: 17,
          stageTime: "00:00:53",
          overallTime: "00:00:53",
        },
        "stage-3": {
          stagePosition: 14,
          overallPosition: 16,
          stageTime: "00:00:04",
          overallTime: "00:00:53",
        },
      },
      312: {
        id: 312,
        startno: 85,
        firstName: "Sebastian",
        lastName: "Langeveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-01-17",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 93,
          overallPosition: 93,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 12,
          overallPosition: 34,
          stageTime: "00:00:35",
          overallTime: "00:01:32",
        },
        "stage-3": {
          stagePosition: 64,
          overallPosition: 30,
          stageTime: "00:00:04",
          overallTime: "00:01:32",
        },
      },
      7011: {
        id: 7011,
        startno: 147,
        firstName: "Albert",
        lastName: "Torres",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-04-26",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 143,
          overallPosition: 143,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 108,
          overallPosition: 129,
          stageTime: "00:01:31",
          overallTime: "00:07:18",
        },
        "stage-3": {
          stagePosition: 128,
          overallPosition: 127,
          stageTime: "00:01:59",
          overallTime: "00:09:13",
        },
      },
      264: {
        id: 264,
        startno: 41,
        firstName: "Jakob",
        lastName: "Fuglsang",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1985-03-22",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "N",
        "stage-1": { stagePosition: 999, stageTime: "00:00:00" },
      },
      16787: {
        id: 16787,
        startno: 121,
        firstName: "Jenthe",
        lastName: "Biermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-30",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 69,
          overallPosition: 69,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 114,
          overallPosition: 84,
          stageTime: "00:01:37",
          overallTime: "00:02:34",
        },
        "stage-3": {
          stagePosition: 60,
          overallPosition: 69,
          stageTime: "00:00:04",
          overallTime: "00:02:34",
        },
      },
      46163: {
        id: 46163,
        startno: 195,
        firstName: "Marc",
        lastName: "Hirschi",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-08-24",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 71,
          overallPosition: 71,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 42,
          overallPosition: 49,
          stageTime: "00:00:56",
          overallTime: "00:01:53",
        },
        "stage-3": {
          stagePosition: 44,
          overallPosition: 41,
          stageTime: "00:00:04",
          overallTime: "00:01:53",
        },
      },
      71213: {
        id: 71213,
        startno: 205,
        firstName: "Laurenz",
        lastName: "Rex",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-12-15",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 49,
          overallPosition: 49,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 134,
          overallPosition: 95,
          stageTime: "00:01:53",
          overallTime: "00:02:50",
        },
        "stage-3": {
          stagePosition: 109,
          overallPosition: 96,
          stageTime: "00:01:40",
          overallTime: "00:04:26",
        },
      },
      16724: {
        id: 16724,
        startno: 91,
        firstName: "Stefan",
        lastName: "K\u00fcng",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-11-16",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 15,
          overallPosition: 19,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 3,
          overallPosition: 3,
          stageTime: "00:00:20",
          overallTime: "00:00:20",
        },
        "stage-3": {
          stagePosition: 35,
          overallPosition: 3,
          stageTime: "00:00:04",
          overallTime: "00:00:20",
        },
      },
      120: {
        id: 120,
        startno: 54,
        firstName: "Heinrich",
        lastName: "Haussler",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1984-02-25",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 33,
          overallPosition: 33,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 112,
          overallPosition: 36,
          stageTime: "00:01:36",
          overallTime: "00:01:36",
        },
        "stage-3": {
          stagePosition: 41,
          overallPosition: 31,
          stageTime: "00:00:04",
          overallTime: "00:01:36",
        },
      },
      27097: {
        id: 27097,
        startno: 133,
        firstName: "Edoardo",
        lastName: "Affini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-06-24",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 66,
          overallPosition: 66,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 2,
          overallPosition: 23,
          stageTime: "00:00:15",
          overallTime: "00:01:12",
        },
        "stage-3": {
          stagePosition: 134,
          overallPosition: 91,
          stageTime: "00:02:52",
          overallTime: "00:04:00",
        },
      },
      18574: {
        id: 18574,
        startno: 206,
        firstName: "Ludovic",
        lastName: "Robeet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-05-22",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 135,
          overallPosition: 135,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 143,
          overallPosition: 140,
          stageTime: "00:02:10",
          overallTime: "00:07:57",
        },
        "stage-3": {
          stagePosition: 108,
          overallPosition: 130,
          stageTime: "00:01:40",
          overallTime: "00:09:33",
        },
      },
      16786: {
        id: 16786,
        startno: 161,
        firstName: "Tiesj",
        lastName: "Benoot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-03-11",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 21,
          overallPosition: 6,
          stageTime: "00:00:00",
          overallTime: "00:00:07",
        },
        "stage-2": {
          stagePosition: 27,
          overallPosition: 11,
          stageTime: "00:00:49",
          overallTime: "00:00:46",
        },
        "stage-3": {
          stagePosition: 40,
          overallPosition: 11,
          stageTime: "00:00:04",
          overallTime: "00:00:46",
        },
      },
      20832: {
        id: 20832,
        startno: 4,
        firstName: "Jonas",
        lastName: "Rickaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-02-07",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 63,
          overallPosition: 63,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 83,
          overallPosition: 65,
          stageTime: "00:01:17",
          overallTime: "00:02:14",
        },
        "stage-3": {
          stagePosition: 90,
          overallPosition: 75,
          stageTime: "00:00:42",
          overallTime: "00:02:52",
        },
      },
      361: {
        id: 361,
        startno: 64,
        firstName: "Maciej",
        lastName: "Bodnar",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1985-03-07",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 114,
          overallPosition: 114,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 26,
          overallPosition: 115,
          stageTime: "00:00:46",
          overallTime: "00:06:33",
        },
        "stage-3": {
          stagePosition: 139,
          overallPosition: 136,
          stageTime: "00:03:44",
          overallTime: "00:10:13",
        },
      },
      2327: {
        id: 2327,
        startno: 153,
        firstName: "Luke",
        lastName: "Durbridge",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 25,
          overallPosition: 27,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 16,
          overallPosition: 8,
          stageTime: "00:00:39",
          overallTime: "00:00:39",
        },
        "stage-3": {
          stagePosition: 3,
          overallPosition: 4,
          stageTime: "00:00:00",
          overallTime: "00:00:22",
        },
      },
      38341: {
        id: 38341,
        startno: 217,
        firstName: "Thimo",
        lastName: "Willems",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-02-09",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 40,
          overallPosition: 40,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 126,
          overallPosition: 90,
          stageTime: "00:01:48",
          overallTime: "00:02:45",
        },
        "stage-3": {
          stagePosition: 5,
          overallPosition: 72,
          stageTime: "00:00:00",
          overallTime: "00:02:39",
        },
      },
      5685: {
        id: 5685,
        startno: 34,
        firstName: "Anthony",
        lastName: "Jullien",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-03-05",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 59,
          overallPosition: 59,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 139,
          overallPosition: 97,
          stageTime: "00:01:59",
          overallTime: "00:02:56",
        },
        "stage-3": {
          stagePosition: 48,
          overallPosition: 77,
          stageTime: "00:00:04",
          overallTime: "00:02:56",
        },
      },
      41293: {
        id: 41293,
        startno: 14,
        firstName: "Alvaro",
        lastName: "Hodeg",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-09-16",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 3,
          overallPosition: 3,
          stageTime: "00:00:00",
          overallTime: "00:00:06",
        },
        "stage-2": {
          stagePosition: 100,
          overallPosition: 29,
          stageTime: "00:01:25",
          overallTime: "00:01:21",
        },
        "stage-3": {
          stagePosition: 25,
          overallPosition: 27,
          stageTime: "00:00:04",
          overallTime: "00:01:21",
        },
      },
      716: {
        id: 716,
        startno: 101,
        firstName: "Geraint",
        lastName: "Thomas",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1986-05-25",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 88,
          overallPosition: 88,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 45,
          overallPosition: 50,
          stageTime: "00:00:57",
          overallTime: "00:01:54",
        },
        "stage-3": {
          stagePosition: 92,
          overallPosition: 68,
          stageTime: "00:00:42",
          overallTime: "00:02:32",
        },
      },
      45419: {
        id: 45419,
        startno: 174,
        firstName: "Andreas",
        lastName: "Stokbro",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-04-08",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 84,
          overallPosition: 84,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 62,
          overallPosition: 57,
          stageTime: "00:01:08",
          overallTime: "00:02:05",
        },
        "stage-3": {
          stagePosition: 72,
          overallPosition: 47,
          stageTime: "00:00:04",
          overallTime: "00:02:05",
        },
      },
      37430: {
        id: 37430,
        startno: 3,
        firstName: "Senne",
        lastName: "Leysen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-03-18",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 68,
          overallPosition: 68,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 22,
          overallPosition: 39,
          stageTime: "00:00:45",
          overallTime: "00:01:42",
        },
        "stage-3": {
          stagePosition: 97,
          overallPosition: 74,
          stageTime: "00:01:06",
          overallTime: "00:02:44",
        },
      },
      37294: {
        id: 37294,
        startno: 75,
        firstName: "Szymon",
        lastName: "Sajnok",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-08-24",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 103,
          overallPosition: 103,
          stageTime: "00:01:51",
          overallTime: "00:02:01",
        },
        "stage-2": {
          stagePosition: 81,
          overallPosition: 99,
          stageTime: "00:01:16",
          overallTime: "00:03:07",
        },
        "stage-3": {
          stagePosition: 99,
          overallPosition: 93,
          stageTime: "00:01:06",
          overallTime: "00:04:09",
        },
      },
      9042: {
        id: 9042,
        startno: 24,
        firstName: "Jasper",
        lastName: "De Buyst",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-11-24",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 91,
          overallPosition: 91,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 67,
          overallPosition: 58,
          stageTime: "00:01:09",
          overallTime: "00:02:06",
        },
        "stage-3": {
          stagePosition: 136,
          overallPosition: 104,
          stageTime: "00:03:39",
          overallTime: "00:05:41",
        },
      },
      3147: {
        id: 3147,
        startno: 113,
        firstName: "Andrea",
        lastName: "Pasqualon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1988-01-02",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 45,
          overallPosition: 45,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 101,
          overallPosition: 76,
          stageTime: "00:01:27",
          overallTime: "00:02:24",
        },
        "stage-3": {
          stagePosition: 24,
          overallPosition: 58,
          stageTime: "00:00:04",
          overallTime: "00:02:24",
        },
      },
      3930: {
        id: 3930,
        startno: 186,
        firstName: "Toms",
        lastName: "Skujins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1991-06-15",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 27,
          overallPosition: 29,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 66,
          overallPosition: 22,
          stageTime: "00:01:09",
          overallTime: "00:01:09",
        },
        "stage-3": {
          stagePosition: 79,
          overallPosition: 21,
          stageTime: "00:00:04",
          overallTime: "00:01:09",
        },
      },
      16615: {
        id: 16615,
        startno: 124,
        firstName: "Mads",
        lastName: "W\u00fcrtz Schmidt",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-03-31",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 73,
          overallPosition: 73,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 51,
          overallPosition: 55,
          stageTime: "00:01:00",
          overallTime: "00:01:57",
        },
        "stage-3": {
          stagePosition: 54,
          overallPosition: 45,
          stageTime: "00:00:04",
          overallTime: "00:01:57",
        },
      },
      6409: {
        id: 6409,
        startno: 145,
        firstName: "Lluis",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-10-15",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 95,
          overallPosition: 95,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 31,
          overallPosition: 43,
          stageTime: "00:00:51",
          overallTime: "00:01:48",
        },
        "stage-3": {
          stagePosition: 36,
          overallPosition: 35,
          stageTime: "00:00:04",
          overallTime: "00:01:48",
        },
      },
      3199: {
        id: 3199,
        startno: 45,
        firstName: "Hugo",
        lastName: "Houle",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-09-27",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 140,
          overallPosition: 140,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 20,
          overallPosition: 114,
          stageTime: "00:00:44",
          overallTime: "00:06:31",
        },
        "stage-3": {
          stagePosition: 71,
          overallPosition: 109,
          stageTime: "00:00:04",
          overallTime: "00:06:31",
        },
      },
      70759: {
        id: 70759,
        startno: 125,
        firstName: "Alexis",
        lastName: "Renard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-06-01",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 64,
          overallPosition: 64,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 93,
          overallPosition: 71,
          stageTime: "00:01:22",
          overallTime: "00:02:19",
        },
        "stage-3": {
          stagePosition: 82,
          overallPosition: 78,
          stageTime: "00:00:42",
          overallTime: "00:02:57",
        },
      },
      865: {
        id: 865,
        startno: 201,
        firstName: "Timothy",
        lastName: "Dupont",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-11-01",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 104,
          overallPosition: 104,
          stageTime: "00:01:51",
          overallTime: "00:02:01",
        },
        "stage-2": {
          stagePosition: 135,
          overallPosition: 107,
          stageTime: "00:01:55",
          overallTime: "00:03:46",
        },
        "stage-3": {
          stagePosition: 37,
          overallPosition: 87,
          stageTime: "00:00:04",
          overallTime: "00:03:46",
        },
      },
      65036: {
        id: 65036,
        startno: 96,
        firstName: "Jake",
        lastName: "Stewart",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-10-02",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 79,
          overallPosition: 79,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 49,
          overallPosition: 54,
          stageTime: "00:00:59",
          overallTime: "00:01:56",
        },
        "stage-3": {
          stagePosition: 29,
          overallPosition: 44,
          stageTime: "00:00:04",
          overallTime: "00:01:56",
        },
      },
      376: {
        id: 376,
        startno: 57,
        firstName: "Marcel",
        lastName: "Sieberg",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1982-04-30",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 74,
          overallPosition: 74,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 144,
          overallPosition: 100,
          stageTime: "00:02:12",
          overallTime: "00:03:09",
        },
        "stage-3": {
          stagePosition: 110,
          overallPosition: 99,
          stageTime: "00:01:40",
          overallTime: "00:04:45",
        },
      },
      11313: {
        id: 11313,
        startno: 136,
        firstName: "Mike",
        lastName: "Teunissen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-08-25",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 9,
          overallPosition: 4,
          stageTime: "00:00:00",
          overallTime: "00:00:06",
        },
        "stage-2": {
          stagePosition: 44,
          overallPosition: 18,
          stageTime: "00:00:57",
          overallTime: "00:00:53",
        },
        "stage-3": {
          stagePosition: 11,
          overallPosition: 17,
          stageTime: "00:00:04",
          overallTime: "00:00:53",
        },
      },
      10952: {
        id: 10952,
        startno: 213,
        firstName: "Thomas",
        lastName: "Sprengers",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-02-05",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 70,
          overallPosition: 70,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 110,
          overallPosition: 82,
          stageTime: "00:01:34",
          overallTime: "00:02:31",
        },
        "stage-3": {
          stagePosition: 63,
          overallPosition: 66,
          stageTime: "00:00:04",
          overallTime: "00:02:31",
        },
      },
      37219: {
        id: 37219,
        startno: 166,
        firstName: "Casper",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-03-15",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 81,
          overallPosition: 81,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 24,
          overallPosition: 40,
          stageTime: "00:00:46",
          overallTime: "00:01:43",
        },
        "stage-3": {
          stagePosition: 49,
          overallPosition: 33,
          stageTime: "00:00:04",
          overallTime: "00:01:43",
        },
      },
      7964: {
        id: 7964,
        startno: 7,
        firstName: "Gianni",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-11-19",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 26,
          overallPosition: 28,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 82,
          overallPosition: 26,
          stageTime: "00:01:16",
          overallTime: "00:01:16",
        },
        "stage-3": {
          stagePosition: 55,
          overallPosition: 24,
          stageTime: "00:00:04",
          overallTime: "00:01:16",
        },
      },
      2172: {
        id: 2172,
        startno: 67,
        firstName: "Juraj",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1988-12-23",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 125,
          overallPosition: 125,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 146,
          overallPosition: 141,
          stageTime: "00:02:16",
          overallTime: "00:08:03",
        },
        "stage-3": {
          stagePosition: 94,
          overallPosition: 120,
          stageTime: "00:00:42",
          overallTime: "00:08:41",
        },
      },
      42175: {
        id: 42175,
        startno: 157,
        firstName: "Callum",
        lastName: "Scotson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-08-10",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 124,
          overallPosition: 124,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 54,
          overallPosition: 119,
          stageTime: "00:01:00",
          overallTime: "00:06:47",
        },
        "stage-3": {
          stagePosition: 127,
          overallPosition: 122,
          stageTime: "00:01:59",
          overallTime: "00:08:42",
        },
      },
      63055: {
        id: 63055,
        startno: 52,
        firstName: "Fred",
        lastName: "Wright",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-06-13",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 42,
          overallPosition: 42,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 15,
          overallPosition: 35,
          stageTime: "00:00:38",
          overallTime: "00:01:35",
        },
        "stage-3": {
          stagePosition: 93,
          overallPosition: 50,
          stageTime: "00:00:42",
          overallTime: "00:02:13",
        },
      },
      14742: {
        id: 14742,
        startno: 33,
        firstName: "Julien",
        lastName: "Duval",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-05-27",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 55,
          overallPosition: 55,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 131,
          overallPosition: 93,
          stageTime: "00:01:52",
          overallTime: "00:02:49",
        },
        "stage-3": {
          stagePosition: 95,
          overallPosition: 88,
          stageTime: "00:01:06",
          overallTime: "00:03:51",
        },
      },
      16457: {
        id: 16457,
        startno: 17,
        firstName: "Stijn",
        lastName: "Steels",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-08-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 32,
          overallPosition: 32,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 98,
          overallPosition: 32,
          stageTime: "00:01:23",
          overallTime: "00:01:23",
        },
        "stage-3": {
          stagePosition: 130,
          overallPosition: 90,
          stageTime: "00:02:38",
          overallTime: "00:03:57",
        },
      },
      926: {
        id: 926,
        startno: 105,
        firstName: "Michal",
        lastName: "Golas",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1984-04-29",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 86,
          overallPosition: 86,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 103,
          overallPosition: 78,
          stageTime: "00:01:28",
          overallTime: "00:02:25",
        },
        "stage-3": {
          stagePosition: 65,
          overallPosition: 61,
          stageTime: "00:00:04",
          overallTime: "00:02:25",
        },
      },
      16793: {
        id: 16793,
        startno: 181,
        firstName: "Mads",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-12-18",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 5,
          overallPosition: 11,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 28,
          overallPosition: 13,
          stageTime: "00:00:50",
          overallTime: "00:00:50",
        },
        "stage-3": {
          stagePosition: 10,
          overallPosition: 13,
          stageTime: "00:00:04",
          overallTime: "00:00:50",
        },
      },
      941: {
        id: 941,
        startno: 84,
        firstName: "Mitchell",
        lastName: "Docker",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1986-10-02",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 94,
          overallPosition: 94,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 80,
          overallPosition: 64,
          stageTime: "00:01:16",
          overallTime: "00:02:13",
        },
        "stage-3": {
          stagePosition: 145,
          overallPosition: 119,
          stageTime: "00:06:31",
          overallTime: "00:08:40",
        },
      },
      11828: {
        id: 11828,
        startno: 142,
        firstName: "Ivan",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-11-20",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 35,
          overallPosition: 35,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 95,
          overallPosition: 74,
          stageTime: "00:01:22",
          overallTime: "00:02:19",
        },
        "stage-3": {
          stagePosition: 18,
          overallPosition: 56,
          stageTime: "00:00:04",
          overallTime: "00:02:19",
        },
      },
      1717: {
        id: 1717,
        startno: 27,
        firstName: "Tosh",
        lastName: "Van der Sande",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-11-28",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 11,
          overallPosition: 16,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 87,
          overallPosition: 27,
          stageTime: "00:01:19",
          overallTime: "00:01:19",
        },
        "stage-3": {
          stagePosition: 19,
          overallPosition: 25,
          stageTime: "00:00:04",
          overallTime: "00:01:19",
        },
      },
      7730: {
        id: 7730,
        startno: 116,
        firstName: "Danny",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-07-26",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 6,
          overallPosition: 12,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 58,
          overallPosition: 20,
          stageTime: "00:01:06",
          overallTime: "00:01:06",
        },
        "stage-3": {
          stagePosition: 8,
          overallPosition: 19,
          stageTime: "00:00:04",
          overallTime: "00:01:06",
        },
      },
      66265: {
        id: 66265,
        startno: 193,
        firstName: "Mikkel",
        lastName: "Bjerg",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-11-03",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 72,
          overallPosition: 72,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 25,
          overallPosition: 41,
          stageTime: "00:00:46",
          overallTime: "00:01:43",
        },
        "stage-3": {
          stagePosition: 73,
          overallPosition: 34,
          stageTime: "00:00:04",
          overallTime: "00:01:43",
        },
      },
      48539: {
        id: 48539,
        startno: 194,
        firstName: "Brandon",
        lastName: "McNulty",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1998-04-02",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 133,
          overallPosition: 133,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 10,
          overallPosition: 111,
          stageTime: "00:00:31",
          overallTime: "00:06:18",
        },
        "stage-3": {
          stagePosition: 39,
          overallPosition: 106,
          stageTime: "00:00:04",
          overallTime: "00:06:18",
        },
      },
      6260: {
        id: 6260,
        startno: 92,
        firstName: "Antoine",
        lastName: "Duchesne",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1991-09-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 87,
          overallPosition: 87,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 132,
          overallPosition: 94,
          stageTime: "00:01:53",
          overallTime: "00:02:50",
        },
        "stage-3": {
          stagePosition: 89,
          overallPosition: 84,
          stageTime: "00:00:42",
          overallTime: "00:03:28",
        },
      },
      16816: {
        id: 16816,
        startno: 53,
        firstName: "Phil",
        lastName: "Bauhaus",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-07-08",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 2,
          overallPosition: 2,
          stageTime: "00:00:00",
          overallTime: "00:00:04",
        },
        "stage-2": {
          stagePosition: 136,
          overallPosition: 47,
          stageTime: "00:01:56",
          overallTime: "00:01:50",
        },
        "stage-3": {
          stagePosition: 7,
          overallPosition: 38,
          stageTime: "00:00:04",
          overallTime: "00:01:50",
        },
      },
      2324: {
        id: 2324,
        startno: 131,
        firstName: "Tom",
        lastName: "Dumoulin",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1990-11-11",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 52,
          overallPosition: 52,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 6,
          overallPosition: 28,
          stageTime: "00:00:23",
          overallTime: "00:01:20",
        },
        "stage-3": {
          stagePosition: 43,
          overallPosition: 26,
          stageTime: "00:00:04",
          overallTime: "00:01:20",
        },
      },
      37996: {
        id: 37996,
        startno: 204,
        firstName: "Milan",
        lastName: "Menten",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-10-31",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 39,
          overallPosition: 39,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 149,
          overallPosition: 104,
          stageTime: "00:02:35",
          overallTime: "00:03:32",
        },
        "stage-3": {
          stagePosition: 101,
          overallPosition: 97,
          stageTime: "00:01:06",
          overallTime: "00:04:34",
        },
      },
      5306: {
        id: 5306,
        startno: 163,
        firstName: "Nikias",
        lastName: "Arndt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1991-11-18",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 16,
          overallPosition: 20,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 52,
          overallPosition: 19,
          stageTime: "00:01:00",
          overallTime: "00:01:00",
        },
        "stage-3": {
          stagePosition: 50,
          overallPosition: 18,
          stageTime: "00:00:04",
          overallTime: "00:01:00",
        },
      },
      28169: {
        id: 28169,
        startno: 2,
        firstName: "Dries",
        lastName: "De Bondt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-07-04",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 99,
          overallPosition: 99,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 94,
          overallPosition: 72,
          stageTime: "00:01:22",
          overallTime: "00:02:19",
        },
        "stage-3": {
          stagePosition: 68,
          overallPosition: 55,
          stageTime: "00:00:04",
          overallTime: "00:02:19",
        },
      },
      16694: {
        id: 16694,
        startno: 63,
        firstName: "Erik",
        lastName: "Baska",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1994-01-12",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 53,
          overallPosition: 53,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 119,
          overallPosition: 88,
          stageTime: "00:01:41",
          overallTime: "00:02:38",
        },
        "stage-3": {
          stagePosition: 140,
          overallPosition: 107,
          stageTime: "00:03:44",
          overallTime: "00:06:18",
        },
      },
      704: {
        id: 704,
        startno: 152,
        firstName: "Sam",
        lastName: "Bewley",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1987-07-22",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 113,
          overallPosition: 113,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 113,
          overallPosition: 131,
          stageTime: "00:01:36",
          overallTime: "00:07:23",
        },
        "stage-3": {
          stagePosition: 144,
          overallPosition: 140,
          stageTime: "00:06:31",
          overallTime: "00:13:50",
        },
      },
      37434: {
        id: 37434,
        startno: 216,
        firstName: "Jordi",
        lastName: "Warlop",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-06-04",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 62,
          overallPosition: 62,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 124,
          overallPosition: 89,
          stageTime: "00:01:47",
          overallTime: "00:02:44",
        },
        "stage-3": {
          stagePosition: 106,
          overallPosition: 89,
          stageTime: "00:01:16",
          overallTime: "00:03:56",
        },
      },
      22682: {
        id: 22682,
        startno: 32,
        firstName: "Oliver",
        lastName: "Naesen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-09-16",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 13,
          overallPosition: 18,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 96,
          overallPosition: 31,
          stageTime: "00:01:22",
          overallTime: "00:01:22",
        },
        "stage-3": {
          stagePosition: 62,
          overallPosition: 29,
          stageTime: "00:00:04",
          overallTime: "00:01:22",
        },
      },
      752: {
        id: 752,
        startno: 13,
        firstName: "Dries",
        lastName: "Devenyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1983-07-22",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 127,
          overallPosition: 127,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 56,
          overallPosition: 121,
          stageTime: "00:01:02",
          overallTime: "00:06:49",
        },
        "stage-3": {
          stagePosition: 113,
          overallPosition: 123,
          stageTime: "00:01:59",
          overallTime: "00:08:44",
        },
      },
      38164: {
        id: 38164,
        startno: 87,
        firstName: "Julius",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-10-23",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 139,
          overallPosition: 139,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 34,
          overallPosition: 116,
          stageTime: "00:00:52",
          overallTime: "00:06:39",
        },
        "stage-3": {
          stagePosition: 51,
          overallPosition: 110,
          stageTime: "00:00:04",
          overallTime: "00:06:39",
        },
      },
      6100: {
        id: 6100,
        startno: 173,
        firstName: "Lasse",
        lastName: "Norman Hansen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-11",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 144,
          overallPosition: 144,
          stageTime: "00:13:50",
          overallTime: "00:14:00",
        },
        "stage-2": {
          stagePosition: 59,
          overallPosition: 145,
          stageTime: "00:01:06",
          overallTime: "00:14:56",
        },
        "stage-3": {
          stagePosition: 135,
          overallPosition: 146,
          stageTime: "00:03:27",
          overallTime: "00:18:19",
        },
      },
      8377: {
        id: 8377,
        startno: 1,
        firstName: "Tim",
        lastName: "Merlier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-10-30",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 1,
          overallPosition: 1,
          stageTime: "03:32:20",
          overallTime: "03:32:10",
        },
        "stage-2": {
          stagePosition: 53,
          overallPosition: 14,
          stageTime: "00:01:00",
          overallTime: "00:00:50",
        },
        "stage-3": {
          stagePosition: 22,
          overallPosition: 14,
          stageTime: "00:00:04",
          overallTime: "00:00:50",
        },
      },
      14737: {
        id: 14737,
        startno: 71,
        firstName: "Christophe",
        lastName: "Laporte",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-12-11",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 10,
          overallPosition: 15,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 9,
          overallPosition: 6,
          stageTime: "00:00:29",
          overallTime: "00:00:29",
        },
        "stage-3": {
          stagePosition: 23,
          overallPosition: 7,
          stageTime: "00:00:04",
          overallTime: "00:00:29",
        },
      },
      174: {
        id: 174,
        startno: 23,
        firstName: "Philippe",
        lastName: "Gilbert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-07-05",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 61,
          overallPosition: 61,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 91,
          overallPosition: 69,
          stageTime: "00:01:20",
          overallTime: "00:02:17",
        },
        "stage-3": {
          stagePosition: 59,
          overallPosition: 54,
          stageTime: "00:00:04",
          overallTime: "00:02:17",
        },
      },
      16581: {
        id: 16581,
        startno: 111,
        firstName: "Aim\u00e9",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-06-17",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 90,
          overallPosition: 90,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 102,
          overallPosition: 77,
          stageTime: "00:01:28",
          overallTime: "00:02:25",
        },
        "stage-3": {
          stagePosition: 53,
          overallPosition: 60,
          stageTime: "00:00:04",
          overallTime: "00:02:25",
        },
      },
      16646: {
        id: 16646,
        startno: 185,
        firstName: "Ryan",
        lastName: "Mullen",
        nationCode: "IRL",
        nationName: "Ireland",
        birthDate: "1994-08-07",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 102,
          overallPosition: 102,
          stageTime: "00:01:51",
          overallTime: "00:02:01",
        },
        "stage-2": {
          stagePosition: 55,
          overallPosition: 96,
          stageTime: "00:01:01",
          overallTime: "00:02:52",
        },
        "stage-3": {
          stagePosition: 112,
          overallPosition: 100,
          stageTime: "00:01:59",
          overallTime: "00:04:47",
        },
      },
      1267: {
        id: 1267,
        startno: 117,
        firstName: "Pieter",
        lastName: "Vanspeybrouck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-02-10",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 130,
          overallPosition: 130,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 133,
          overallPosition: 136,
          stageTime: "00:01:53",
          overallTime: "00:07:40",
        },
        "stage-3": {
          stagePosition: 122,
          overallPosition: 131,
          stageTime: "00:01:59",
          overallTime: "00:09:35",
        },
      },
      14126: {
        id: 14126,
        startno: 143,
        firstName: "Davide",
        lastName: "Villella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1991-06-27",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 136,
          overallPosition: 136,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 123,
          overallPosition: 133,
          stageTime: "00:01:46",
          overallTime: "00:07:33",
        },
        "stage-3": {
          stagePosition: 118,
          overallPosition: 128,
          stageTime: "00:01:59",
          overallTime: "00:09:28",
        },
      },
      5836: {
        id: 5836,
        startno: 44,
        firstName: "Dmitriy",
        lastName: "Gruzdev",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-03-13",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 100,
          overallPosition: 100,
          stageTime: "00:01:51",
          overallTime: "00:02:01",
        },
        "stage-2": {
          stagePosition: 97,
          overallPosition: 101,
          stageTime: "00:01:22",
          overallTime: "00:03:13",
        },
        "stage-3": {
          stagePosition: 141,
          overallPosition: 113,
          stageTime: "00:03:59",
          overallTime: "00:07:08",
        },
      },
      37384: {
        id: 37384,
        startno: 123,
        firstName: "Norman",
        lastName: "Vahtra",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1996-11-23",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 80,
          overallPosition: 80,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 73,
          overallPosition: 62,
          stageTime: "00:01:14",
          overallTime: "00:02:11",
        },
        "stage-3": {
          stagePosition: 102,
          overallPosition: 81,
          stageTime: "00:01:06",
          overallTime: "00:03:13",
        },
      },
      624: {
        id: 624,
        startno: 197,
        firstName: "Maximiliano",
        lastName: "Richeze",
        nationCode: "ARG",
        nationName: "Argentina",
        birthDate: "1983-03-07",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 112,
          overallPosition: 112,
          stageTime: "00:05:39",
          overallTime: "00:05:49",
        },
        "stage-2": {
          stagePosition: 64,
          overallPosition: 120,
          stageTime: "00:01:09",
          overallTime: "00:06:48",
        },
        "stage-3": {
          stagePosition: 100,
          overallPosition: 116,
          stageTime: "00:01:06",
          overallTime: "00:07:50",
        },
      },
      27040: {
        id: 27040,
        startno: 86,
        firstName: "Logan",
        lastName: "Owen",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1995-03-25",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 28,
          overallPosition: 30,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 75,
          overallPosition: 24,
          stageTime: "00:01:14",
          overallTime: "00:01:14",
        },
        "stage-3": {
          stagePosition: 61,
          overallPosition: 22,
          stageTime: "00:00:04",
          overallTime: "00:01:14",
        },
      },
      21855: {
        id: 21855,
        startno: 95,
        firstName: "Miles",
        lastName: "Scotson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-01-18",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 77,
          overallPosition: 77,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 36,
          overallPosition: 45,
          stageTime: "00:00:52",
          overallTime: "00:01:49",
        },
        "stage-3": {
          stagePosition: 76,
          overallPosition: 37,
          stageTime: "00:00:04",
          overallTime: "00:01:49",
        },
      },
      16687: {
        id: 16687,
        startno: 56,
        firstName: "Matej",
        lastName: "Mohoric",
        nationCode: "SLO",
        nationName: "Slovenia",
        birthDate: "1994-10-19",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 14,
          overallPosition: 5,
          stageTime: "00:00:00",
          overallTime: "00:00:06",
        },
        "stage-2": {
          stagePosition: 19,
          overallPosition: 7,
          stageTime: "00:00:40",
          overallTime: "00:00:36",
        },
        "stage-3": {
          stagePosition: 17,
          overallPosition: 8,
          stageTime: "00:00:04",
          overallTime: "00:00:36",
        },
      },
      22133: {
        id: 22133,
        startno: 135,
        firstName: "Timo",
        lastName: "Roosen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-01-11",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 47,
          overallPosition: 47,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 35,
          overallPosition: 44,
          stageTime: "00:00:52",
          overallTime: "00:01:49",
        },
        "stage-3": {
          stagePosition: 70,
          overallPosition: 36,
          stageTime: "00:00:04",
          overallTime: "00:01:49",
        },
      },
      65033: {
        id: 65033,
        startno: 212,
        firstName: "Arne",
        lastName: "Marit",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-01-21",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 36,
          overallPosition: 36,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 116,
          overallPosition: 86,
          stageTime: "00:01:38",
          overallTime: "00:02:35",
        },
        "stage-3": {
          stagePosition: 80,
          overallPosition: 70,
          stageTime: "00:00:04",
          overallTime: "00:02:35",
        },
      },
      38146: {
        id: 38146,
        startno: 165,
        firstName: "Joris",
        lastName: "Nieuwenhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-11",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 92,
          overallPosition: 92,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 92,
          overallPosition: 70,
          stageTime: "00:01:21",
          overallTime: "00:02:18",
        },
        "stage-3": {
          stagePosition: 84,
          overallPosition: 76,
          stageTime: "00:00:42",
          overallTime: "00:02:56",
        },
      },
      6266: {
        id: 6266,
        startno: 6,
        firstName: "Petr",
        lastName: "Vakoc",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1992-07-11",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 119,
          overallPosition: 119,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 130,
          overallPosition: 135,
          stageTime: "00:01:52",
          overallTime: "00:07:39",
        },
        "stage-3": {
          stagePosition: 87,
          overallPosition: 117,
          stageTime: "00:00:42",
          overallTime: "00:08:17",
        },
      },
      8388: {
        id: 8388,
        startno: 66,
        firstName: "Lukas",
        lastName: "P\u00f6stlberger",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1992-01-10",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 30,
          overallPosition: 7,
          stageTime: "00:00:00",
          overallTime: "00:00:07",
        },
        "stage-2": {
          stagePosition: 30,
          overallPosition: 12,
          stageTime: "00:00:51",
          overallTime: "00:00:48",
        },
        "stage-3": {
          stagePosition: 33,
          overallPosition: 12,
          stageTime: "00:00:04",
          overallTime: "00:00:48",
        },
      },
      50030: {
        id: 50030,
        startno: 156,
        firstName: "Barnabas",
        lastName: "Peak",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1998-11-29",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 46,
          overallPosition: 46,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 47,
          overallPosition: 52,
          stageTime: "00:00:58",
          overallTime: "00:01:55",
        },
        "stage-3": {
          stagePosition: 105,
          overallPosition: 79,
          stageTime: "00:01:06",
          overallTime: "00:02:57",
        },
      },
      1580: {
        id: 1580,
        startno: 42,
        firstName: "Fabio",
        lastName: "Felline",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-03-29",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "N",
        "stage-1": { stagePosition: 998, stageTime: "00:00:00" },
      },
      7580: {
        id: 7580,
        startno: 37,
        firstName: "Gijs",
        lastName: "Van Hoecke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-11-12",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 89,
          overallPosition: 89,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 68,
          overallPosition: 59,
          stageTime: "00:01:10",
          overallTime: "00:02:07",
        },
        "stage-3": {
          stagePosition: 56,
          overallPosition: 48,
          stageTime: "00:00:04",
          overallTime: "00:02:07",
        },
      },
      721: {
        id: 721,
        startno: 16,
        firstName: "Michael",
        lastName: "M\u00f8rk\u00f8v",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1985-04-30",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 78,
          overallPosition: 78,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 76,
          overallPosition: 63,
          stageTime: "00:01:14",
          overallTime: "00:02:11",
        },
        "stage-3": {
          stagePosition: 47,
          overallPosition: 49,
          stageTime: "00:00:04",
          overallTime: "00:02:11",
        },
      },
      20581: {
        id: 20581,
        startno: 104,
        firstName: "Leonardo",
        lastName: "Basso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-12-25",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 96,
          overallPosition: 96,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 115,
          overallPosition: 85,
          stageTime: "00:01:38",
          overallTime: "00:02:35",
        },
        "stage-3": {
          stagePosition: 83,
          overallPosition: 80,
          stageTime: "00:00:42",
          overallTime: "00:03:13",
        },
      },
      9210: {
        id: 9210,
        startno: 177,
        firstName: "Max",
        lastName: "Walscheid",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-06-13",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 7,
          overallPosition: 13,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 5,
          overallPosition: 4,
          stageTime: "00:00:22",
          overallTime: "00:00:22",
        },
        "stage-3": {
          stagePosition: 31,
          overallPosition: 5,
          stageTime: "00:00:04",
          overallTime: "00:00:22",
        },
      },
      46157: {
        id: 46157,
        startno: 83,
        firstName: "Stefan",
        lastName: "Bissegger",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-09-13",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 23,
          overallPosition: 25,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 1,
          overallPosition: 1,
          stageTime: "00:12:08",
          overallTime: "03:44:28",
        },
        "stage-3": {
          stagePosition: 21,
          overallPosition: 1,
          stageTime: "00:00:04",
          overallTime: "07:24:55",
        },
      },
      1310: {
        id: 1310,
        startno: 77,
        firstName: "Elia",
        lastName: "Viviani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-02-07",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 44,
          overallPosition: 44,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 128,
          overallPosition: 92,
          stageTime: "00:01:49",
          overallTime: "00:02:46",
        },
        "stage-3": {
          stagePosition: 119,
          overallPosition: 98,
          stageTime: "00:01:59",
          overallTime: "00:04:41",
        },
      },
      840: {
        id: 840,
        startno: 26,
        firstName: "Roger",
        lastName: "Kluge",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1986-02-05",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 12,
          overallPosition: 17,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 147,
          overallPosition: 73,
          stageTime: "00:02:19",
          overallTime: "00:02:19",
        },
        "stage-3": {
          stagePosition: 129,
          overallPosition: 94,
          stageTime: "00:01:59",
          overallTime: "00:04:14",
        },
      },
      3187: {
        id: 3187,
        startno: 115,
        firstName: "Boy",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1988-01-18",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 43,
          overallPosition: 43,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 111,
          overallPosition: 83,
          stageTime: "00:01:35",
          overallTime: "00:02:32",
        },
        "stage-3": {
          stagePosition: 52,
          overallPosition: 67,
          stageTime: "00:00:04",
          overallTime: "00:02:32",
        },
      },
      18575: {
        id: 18575,
        startno: 192,
        firstName: "Fernando",
        lastName: "Gaviria",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-08-19",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 4,
          overallPosition: 10,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 65,
          overallPosition: 21,
          stageTime: "00:01:09",
          overallTime: "00:01:09",
        },
        "stage-3": {
          stagePosition: 13,
          overallPosition: 20,
          stageTime: "00:00:04",
          overallTime: "00:01:09",
        },
      },
      75998: {
        id: 75998,
        startno: 191,
        firstName: "Finn",
        lastName: "Fisher-Black",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2001-12-21",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 132,
          overallPosition: 132,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 40,
          overallPosition: 117,
          stageTime: "00:00:55",
          overallTime: "00:06:42",
        },
        "stage-3": {
          stagePosition: 88,
          overallPosition: 114,
          stageTime: "00:00:42",
          overallTime: "00:07:20",
        },
      },
      45082: {
        id: 45082,
        startno: 141,
        firstName: "Hector",
        lastName: "Carretero",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-05-28",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 122,
          overallPosition: 122,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 125,
          overallPosition: 134,
          stageTime: "00:01:47",
          overallTime: "00:07:34",
        },
        "stage-3": {
          stagePosition: 116,
          overallPosition: 129,
          stageTime: "00:01:59",
          overallTime: "00:09:29",
        },
      },
      2338: {
        id: 2338,
        startno: 51,
        firstName: "Sonny",
        lastName: "Colbrelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-17",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 20,
          overallPosition: 8,
          stageTime: "00:00:00",
          overallTime: "00:00:08",
        },
        "stage-2": {
          stagePosition: 21,
          overallPosition: 9,
          stageTime: "00:00:44",
          overallTime: "00:00:42",
        },
        "stage-3": {
          stagePosition: 16,
          overallPosition: 9,
          stageTime: "00:00:04",
          overallTime: "00:00:42",
        },
      },
      7579: {
        id: 7579,
        startno: 127,
        firstName: "Tom",
        lastName: "Van Asbroeck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-04-19",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 51,
          overallPosition: 51,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 89,
          overallPosition: 68,
          stageTime: "00:01:20",
          overallTime: "00:02:17",
        },
        "stage-3": {
          stagePosition: 12,
          overallPosition: 53,
          stageTime: "00:00:04",
          overallTime: "00:02:17",
        },
      },
      20822: {
        id: 20822,
        startno: 203,
        firstName: "Arjen",
        lastName: "Livyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-01",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 105,
          overallPosition: 105,
          stageTime: "00:01:51",
          overallTime: "00:02:01",
        },
        "stage-2": {
          stagePosition: 145,
          overallPosition: 109,
          stageTime: "00:02:13",
          overallTime: "00:04:04",
        },
        "stage-3": {
          stagePosition: 124,
          overallPosition: 105,
          stageTime: "00:01:59",
          overallTime: "00:05:59",
        },
      },
      16610: {
        id: 16610,
        startno: 162,
        firstName: "S\u00f8ren Kragh",
        lastName: "Andersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-08-10",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 41,
          overallPosition: 41,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 7,
          overallPosition: 30,
          stageTime: "00:00:24",
          overallTime: "00:01:21",
        },
        "stage-3": {
          stagePosition: 38,
          overallPosition: 28,
          stageTime: "00:00:04",
          overallTime: "00:01:21",
        },
      },
      69890: {
        id: 69890,
        startno: 93,
        firstName: "Attila",
        lastName: "Valter",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1998-06-12",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 142,
          overallPosition: 142,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 72,
          overallPosition: 124,
          stageTime: "00:01:14",
          overallTime: "00:07:01",
        },
        "stage-3": {
          stagePosition: 126,
          overallPosition: 124,
          stageTime: "00:01:59",
          overallTime: "00:08:56",
        },
      },
      2165: {
        id: 2165,
        startno: 62,
        firstName: "Wilco",
        lastName: "Kelderman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1991-03-25",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "N",
        "stage-1": {
          stagePosition: 18,
          overallPosition: 22,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 33,
          overallPosition: 16,
          stageTime: "00:00:51",
          overallTime: "00:00:51",
        },
        "stage-3": { stagePosition: 999, stageTime: "00:00:00" },
      },
      2212: {
        id: 2212,
        startno: 151,
        firstName: "Jack",
        lastName: "Bauer",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1985-04-07",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 75,
          overallPosition: 75,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 41,
          overallPosition: 48,
          stageTime: "00:00:56",
          overallTime: "00:01:53",
        },
        "stage-3": {
          stagePosition: 42,
          overallPosition: 39,
          stageTime: "00:00:04",
          overallTime: "00:01:53",
        },
      },
      64654: {
        id: 64654,
        startno: 215,
        firstName: "Ward",
        lastName: "Vanhoof",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-04-18",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "N",
        "stage-1": {
          stagePosition: 110,
          overallPosition: 110,
          stageTime: "00:03:08",
          overallTime: "00:03:18",
        },
        "stage-2": { stagePosition: 998, stageTime: "00:00:00" },
      },
      150: {
        id: 150,
        startno: 31,
        firstName: "Greg",
        lastName: "Van Avermaet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-05-17",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 57,
          overallPosition: 57,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 104,
          overallPosition: 79,
          stageTime: "00:01:28",
          overallTime: "00:02:25",
        },
        "stage-3": {
          stagePosition: 69,
          overallPosition: 62,
          stageTime: "00:00:04",
          overallTime: "00:02:25",
        },
      },
      27121: {
        id: 27121,
        startno: 12,
        firstName: "Kasper",
        lastName: "Asgreen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-02-08",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 29,
          overallPosition: 9,
          stageTime: "00:00:00",
          overallTime: "00:00:08",
        },
        "stage-2": {
          stagePosition: 4,
          overallPosition: 2,
          stageTime: "00:00:21",
          overallTime: "00:00:19",
        },
        "stage-3": {
          stagePosition: 30,
          overallPosition: 2,
          stageTime: "00:00:04",
          overallTime: "00:00:19",
        },
      },
      190: {
        id: 190,
        startno: 82,
        firstName: "Fumiyuki",
        lastName: "Beppu",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1983-04-10",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 138,
          overallPosition: 138,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 109,
          overallPosition: 130,
          stageTime: "00:01:33",
          overallTime: "00:07:20",
        },
        "stage-3": {
          stagePosition: 147,
          overallPosition: 141,
          stageTime: "00:07:34",
          overallTime: "00:14:50",
        },
      },
      9894: {
        id: 9894,
        startno: 172,
        firstName: "Michael",
        lastName: "Gogl",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-11-04",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 131,
          overallPosition: 131,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 90,
          overallPosition: 128,
          stageTime: "00:01:20",
          overallTime: "00:07:07",
        },
        "stage-3": {
          stagePosition: 123,
          overallPosition: 126,
          stageTime: "00:01:59",
          overallTime: "00:09:02",
        },
      },
      53511: {
        id: 53511,
        startno: 183,
        firstName: "Charlie",
        lastName: "Quarterman",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-09-06",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 116,
          overallPosition: 116,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 50,
          overallPosition: 118,
          stageTime: "00:01:00",
          overallTime: "00:06:47",
        },
        "stage-3": {
          stagePosition: 111,
          overallPosition: 121,
          stageTime: "00:01:59",
          overallTime: "00:08:42",
        },
      },
      1982: {
        id: 1982,
        startno: 73,
        firstName: "Jempy",
        lastName: "Drucker",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1986-09-03",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 101,
          overallPosition: 101,
          stageTime: "00:01:51",
          overallTime: "00:02:01",
        },
        "stage-2": {
          stagePosition: 106,
          overallPosition: 102,
          stageTime: "00:01:31",
          overallTime: "00:03:22",
        },
        "stage-3": {
          stagePosition: 67,
          overallPosition: 82,
          stageTime: "00:00:04",
          overallTime: "00:03:22",
        },
      },
      16573: {
        id: 16573,
        startno: 22,
        firstName: "Caleb",
        lastName: "Ewan",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1994-07-11",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 150,
          overallPosition: 150,
          stageTime: "00:13:50",
          overallTime: "00:14:00",
        },
        "stage-2": {
          stagePosition: 140,
          overallPosition: 149,
          stageTime: "00:01:59",
          overallTime: "00:15:49",
        },
        "stage-3": {
          stagePosition: 9,
          overallPosition: 144,
          stageTime: "00:00:04",
          overallTime: "00:15:49",
        },
      },
      828: {
        id: 828,
        startno: 107,
        firstName: "Ben",
        lastName: "Swift",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1987-11-05",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 58,
          overallPosition: 58,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 99,
          overallPosition: 75,
          stageTime: "00:01:24",
          overallTime: "00:02:21",
        },
        "stage-3": {
          stagePosition: 32,
          overallPosition: 57,
          stageTime: "00:00:04",
          overallTime: "00:02:21",
        },
      },
      37719: {
        id: 37719,
        startno: 184,
        firstName: "Matteo",
        lastName: "Moschetti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-08-14",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 128,
          overallPosition: 128,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 121,
          overallPosition: 132,
          stageTime: "00:01:43",
          overallTime: "00:07:30",
        },
        "stage-3": {
          stagePosition: 142,
          overallPosition: 137,
          stageTime: "00:03:59",
          overallTime: "00:11:25",
        },
      },
      10948: {
        id: 10948,
        startno: 112,
        firstName: "Ludwig",
        lastName: "De Winter",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-12-31",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 148,
          overallPosition: 148,
          stageTime: "00:13:50",
          overallTime: "00:14:00",
        },
        "stage-2": {
          stagePosition: 129,
          overallPosition: 148,
          stageTime: "00:01:52",
          overallTime: "00:15:42",
        },
        "stage-3": {
          stagePosition: 146,
          overallPosition: 148,
          stageTime: "00:07:34",
          overallTime: "00:23:12",
        },
      },
      38481: {
        id: 38481,
        startno: 144,
        firstName: "Mathias",
        lastName: "Norsgaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1997-05-05",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 97,
          overallPosition: 97,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 69,
          overallPosition: 60,
          stageTime: "00:01:10",
          overallTime: "00:02:07",
        },
        "stage-3": {
          stagePosition: 2,
          overallPosition: 40,
          stageTime: "00:00:00",
          overallTime: "00:01:53",
        },
      },
      48461: {
        id: 48461,
        startno: 43,
        firstName: "Samuele",
        lastName: "Battistella",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-11-14",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 141,
          overallPosition: 141,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 57,
          overallPosition: 122,
          stageTime: "00:01:02",
          overallTime: "00:06:49",
        },
        "stage-3": {
          stagePosition: 4,
          overallPosition: 111,
          stageTime: "00:00:00",
          overallTime: "00:06:45",
        },
      },
      1937: {
        id: 1937,
        startno: 122,
        firstName: "Guillaume",
        lastName: "Boivin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1989-05-25",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 65,
          overallPosition: 65,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 118,
          overallPosition: 87,
          stageTime: "00:01:41",
          overallTime: "00:02:38",
        },
        "stage-3": {
          stagePosition: 58,
          overallPosition: 71,
          stageTime: "00:00:04",
          overallTime: "00:02:38",
        },
      },
      407: {
        id: 407,
        startno: 196,
        firstName: "Vegard Stake",
        lastName: "Laengen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1989-02-07",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 126,
          overallPosition: 126,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 78,
          overallPosition: 126,
          stageTime: "00:01:15",
          overallTime: "00:07:02",
        },
        "stage-3": {
          stagePosition: 132,
          overallPosition: 134,
          stageTime: "00:02:47",
          overallTime: "00:09:45",
        },
      },
      6519: {
        id: 6519,
        startno: 207,
        firstName: "Sean",
        lastName: "De Bie",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-03",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 82,
          overallPosition: 82,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 127,
          overallPosition: 91,
          stageTime: "00:01:48",
          overallTime: "00:02:45",
        },
        "stage-3": {
          stagePosition: 81,
          overallPosition: 83,
          stageTime: "00:00:42",
          overallTime: "00:03:23",
        },
      },
      12493: {
        id: 12493,
        startno: 94,
        firstName: "Fabian",
        lastName: "Lienhard",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-09-03",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 118,
          overallPosition: 118,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 88,
          overallPosition: 127,
          stageTime: "00:01:19",
          overallTime: "00:07:06",
        },
        "stage-3": {
          stagePosition: 75,
          overallPosition: 112,
          stageTime: "00:00:04",
          overallTime: "00:07:06",
        },
      },
      80489: {
        id: 80489,
        startno: 55,
        firstName: "Jonathan",
        lastName: "Milan",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-10-01",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 37,
          overallPosition: 37,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 37,
          overallPosition: 46,
          stageTime: "00:00:52",
          overallTime: "00:01:49",
        },
        "stage-3": {
          stagePosition: 91,
          overallPosition: 63,
          stageTime: "00:00:42",
          overallTime: "00:02:27",
        },
      },
      97547: {
        id: 97547,
        startno: 134,
        firstName: "Olav",
        lastName: "Kooij",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "2001-10-17",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 54,
          overallPosition: 54,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 71,
          overallPosition: 61,
          stageTime: "00:01:13",
          overallTime: "00:02:10",
        },
        "stage-3": {
          stagePosition: 131,
          overallPosition: 101,
          stageTime: "00:02:47",
          overallTime: "00:04:53",
        },
      },
      53490: {
        id: 53490,
        startno: 211,
        firstName: "Rune",
        lastName: "Herregodts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-27",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 115,
          overallPosition: 115,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 17,
          overallPosition: 113,
          stageTime: "00:00:39",
          overallTime: "00:06:26",
        },
        "stage-3": {
          stagePosition: 114,
          overallPosition: 118,
          stageTime: "00:01:59",
          overallTime: "00:08:21",
        },
      },
      31208: {
        id: 31208,
        startno: 164,
        firstName: "Cees",
        lastName: "Bol",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-07-27",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 19,
          overallPosition: 23,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 32,
          overallPosition: 15,
          stageTime: "00:00:51",
          overallTime: "00:00:51",
        },
        "stage-3": {
          stagePosition: 28,
          overallPosition: 15,
          stageTime: "00:00:04",
          overallTime: "00:00:51",
        },
      },
      4779: {
        id: 4779,
        startno: 5,
        firstName: "Oscar",
        lastName: "Riesebeek",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-12-23",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 83,
          overallPosition: 83,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 29,
          overallPosition: 42,
          stageTime: "00:00:50",
          overallTime: "00:01:47",
        },
        "stage-3": {
          stagePosition: 86,
          overallPosition: 59,
          stageTime: "00:00:42",
          overallTime: "00:02:25",
        },
      },
      777: {
        id: 777,
        startno: 65,
        firstName: "Daniel",
        lastName: "Oss",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-01-13",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 107,
          overallPosition: 107,
          stageTime: "00:02:19",
          overallTime: "00:02:29",
        },
        "stage-2": {
          stagePosition: 122,
          overallPosition: 108,
          stageTime: "00:01:43",
          overallTime: "00:04:02",
        },
        "stage-3": {
          stagePosition: 138,
          overallPosition: 115,
          stageTime: "00:03:44",
          overallTime: "00:07:42",
        },
      },
      39286: {
        id: 39286,
        startno: 155,
        firstName: "Alexander",
        lastName: "Konychev",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-07-25",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 76,
          overallPosition: 76,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 105,
          overallPosition: 80,
          stageTime: "00:01:31",
          overallTime: "00:02:28",
        },
        "stage-3": {
          stagePosition: 57,
          overallPosition: 64,
          stageTime: "00:00:04",
          overallTime: "00:02:28",
        },
      },
      27257: {
        id: 27257,
        startno: 46,
        firstName: "Yevgeniy",
        lastName: "Gidich",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1996-05-19",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 137,
          overallPosition: 137,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 138,
          overallPosition: 138,
          stageTime: "00:01:58",
          overallTime: "00:07:45",
        },
        "stage-3": {
          stagePosition: 121,
          overallPosition: 133,
          stageTime: "00:01:59",
          overallTime: "00:09:40",
        },
      },
      327: {
        id: 327,
        startno: 36,
        firstName: "Michael",
        lastName: "Sch\u00e4r",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1986-09-29",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 60,
          overallPosition: 60,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 85,
          overallPosition: 67,
          stageTime: "00:01:18",
          overallTime: "00:02:15",
        },
        "stage-3": {
          stagePosition: 78,
          overallPosition: 52,
          stageTime: "00:00:04",
          overallTime: "00:02:15",
        },
      },
      1298: {
        id: 1298,
        startno: 15,
        firstName: "Iljo",
        lastName: "Keisse",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-12-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 123,
          overallPosition: 123,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 74,
          overallPosition: 125,
          stageTime: "00:01:14",
          overallTime: "00:07:01",
        },
        "stage-3": {
          stagePosition: 125,
          overallPosition: 125,
          stageTime: "00:01:59",
          overallTime: "00:08:56",
        },
      },
      745: {
        id: 745,
        startno: 103,
        firstName: "Andrey",
        lastName: "Amador",
        nationCode: "CRC",
        nationName: "Costa Rica",
        birthDate: "1986-08-29",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 50,
          overallPosition: 50,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 46,
          overallPosition: 51,
          stageTime: "00:00:58",
          overallTime: "00:01:55",
        },
        "stage-3": {
          stagePosition: 77,
          overallPosition: 42,
          stageTime: "00:00:04",
          overallTime: "00:01:55",
        },
      },
      28044: {
        id: 28044,
        startno: 176,
        firstName: "Harry",
        lastName: "Tanfield",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1994-11-17",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 145,
          overallPosition: 145,
          stageTime: "00:13:50",
          overallTime: "00:14:00",
        },
        "stage-2": {
          stagePosition: 86,
          overallPosition: 146,
          stageTime: "00:01:18",
          overallTime: "00:15:08",
        },
        "stage-3": {
          stagePosition: 148,
          overallPosition: 147,
          stageTime: "00:07:34",
          overallTime: "00:22:38",
        },
      },
      47307: {
        id: 47307,
        startno: 74,
        firstName: "Emmanuel",
        lastName: "Morin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-03-13",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 117,
          overallPosition: 117,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 142,
          overallPosition: 139,
          stageTime: "00:02:04",
          overallTime: "00:07:51",
        },
        "stage-3": {
          stagePosition: 117,
          overallPosition: 135,
          stageTime: "00:01:59",
          overallTime: "00:09:46",
        },
      },
      40385: {
        id: 40385,
        startno: 76,
        firstName: "Attilio",
        lastName: "Viviani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-10-18",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 121,
          overallPosition: 121,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 148,
          overallPosition: 142,
          stageTime: "00:02:34",
          overallTime: "00:08:21",
        },
        "stage-3": {
          stagePosition: 137,
          overallPosition: 139,
          stageTime: "00:03:39",
          overallTime: "00:11:56",
        },
      },
      1783: {
        id: 1783,
        startno: 25,
        firstName: "Thomas",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-11-06",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 129,
          overallPosition: 129,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 70,
          overallPosition: 123,
          stageTime: "00:01:12",
          overallTime: "00:06:59",
        },
        "stage-3": {
          stagePosition: 143,
          overallPosition: 138,
          stageTime: "00:04:58",
          overallTime: "00:11:53",
        },
      },
      10802: {
        id: 10802,
        startno: 114,
        firstName: "Taco",
        lastName: "van der Hoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-04",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 48,
          overallPosition: 48,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 141,
          overallPosition: 98,
          stageTime: "00:02:01",
          overallTime: "00:02:58",
        },
        "stage-3": {
          stagePosition: 1,
          overallPosition: 73,
          stageTime: "03:40:23",
          overallTime: "00:02:41",
        },
      },
      10382: {
        id: 10382,
        startno: 187,
        firstName: "Edward",
        lastName: "Theuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-30",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 34,
          overallPosition: 34,
          stageTime: "00:00:57",
          overallTime: "00:01:07",
        },
        "stage-2": {
          stagePosition: 61,
          overallPosition: 56,
          stageTime: "00:01:07",
          overallTime: "00:02:04",
        },
        "stage-3": {
          stagePosition: 66,
          overallPosition: 46,
          stageTime: "00:00:04",
          overallTime: "00:02:04",
        },
      },
      6559: {
        id: 6559,
        startno: 175,
        firstName: "Reinardt",
        lastName: "Janse Van Rensburg",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1989-02-03",
        teamId: 21824,
        teamName: "Team Qhubeka NextHash",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 24,
          overallPosition: 26,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 79,
          overallPosition: 25,
          stageTime: "00:01:15",
          overallTime: "00:01:15",
        },
        "stage-3": {
          stagePosition: 15,
          overallPosition: 23,
          stageTime: "00:00:04",
          overallTime: "00:01:15",
        },
      },
      29418: {
        id: 29418,
        startno: 146,
        firstName: "Sebastian",
        lastName: "Mora",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1988-02-19",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 111,
          overallPosition: 111,
          stageTime: "00:03:24",
          overallTime: "00:03:34",
        },
        "stage-2": {
          stagePosition: 43,
          overallPosition: 110,
          stageTime: "00:00:57",
          overallTime: "00:04:21",
        },
        "stage-3": {
          stagePosition: 103,
          overallPosition: 103,
          stageTime: "00:01:06",
          overallTime: "00:05:23",
        },
      },
      40783: {
        id: 40783,
        startno: 47,
        firstName: "Matteo",
        lastName: "Sobrero",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1997-05-14",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 134,
          overallPosition: 134,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 14,
          overallPosition: 112,
          stageTime: "00:00:36",
          overallTime: "00:06:23",
        },
        "stage-3": {
          stagePosition: 74,
          overallPosition: 108,
          stageTime: "00:00:04",
          overallTime: "00:06:23",
        },
      },
      16963: {
        id: 16963,
        startno: 126,
        firstName: "Guy",
        lastName: "Sagiv",
        nationCode: "ISR",
        nationName: "Israel",
        birthDate: "1994-12-05",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 120,
          overallPosition: 120,
          stageTime: "00:05:47",
          overallTime: "00:05:57",
        },
        "stage-2": {
          stagePosition: 137,
          overallPosition: 137,
          stageTime: "00:01:56",
          overallTime: "00:07:43",
        },
        "stage-3": {
          stagePosition: 120,
          overallPosition: 132,
          stageTime: "00:01:59",
          overallTime: "00:09:38",
        },
      },
      38000: {
        id: 38000,
        startno: 202,
        firstName: "Stanislaw",
        lastName: "Aniolkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-01-20",
        teamId: 21852,
        teamName: "Bingoal Pauwels Sauces WB",
        stillInTheRace: "Y",
        "stage-1": {
          stagePosition: 8,
          overallPosition: 14,
          stageTime: "00:00:00",
          overallTime: "00:00:10",
        },
        "stage-2": {
          stagePosition: 117,
          overallPosition: 38,
          stageTime: "00:01:40",
          overallTime: "00:01:40",
        },
        "stage-3": {
          stagePosition: 20,
          overallPosition: 32,
          stageTime: "00:00:04",
          overallTime: "00:01:40",
        },
      },
    },
  }

  const race = "Benelux Tour"
  const raceID = 21

  return (
    <RacepageTdF
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default BeneluxTour2021
